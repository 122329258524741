@use "src/styles/theme";
@use "src/styles/breakpoints";

$widget-wrapper-background-color: var(--color-success-faded);

.wrapper {
    background-color: $widget-wrapper-background-color;
    padding: var(--spacing-small);
    position: relative;

    @include breakpoints.tablet {
        border-radius: var(--border-radius-default);
    }

    --day-circle-color: #{$widget-wrapper-background-color};
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    color: var(--color-success-medium);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-jumbo);

    @include breakpoints.smallerThanTablet {
        font-size: var(--font-size-xlarge);
    }
}

.illustrationContainer {
    width: calc(5 * var(--spacing-mega));
    height: calc(5 * var(--spacing-mega));
    position: absolute;
    inset-block-end: calc(-1 * var(--spacing-medium));
    inset-inline-end: 0;
    opacity: var(--opacity-10);
    color: var(--color-success-deep);
    pointer-events: none;
}
